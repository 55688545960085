<template>
  <a-modal v-model="showPopup" :width='850' :title="titleText" @cancel="handleCancel" @ok="handleConfirm">
    <a-form-model v-bind="layout">
      <a-form-item label="标题">
        <a-input allowClear v-model="params.title" class="w-600" placeholder='标题'></a-input>
      </a-form-item>
      <a-form-item label="内容">
        <a-input
            id="inputKeywordTabDesc"
            v-model="params.content"
            class="w-100-w"
            type="textarea"
            :auto-size="{ minRows: 6, maxRows: 20 }"
            placeholder="文本内容"
        ></a-input>
      </a-form-item>
      <a-form-item label="上传图片">
        <a-upload
            key="upload-cover"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            :file-list="contentImgList"
            @preview="handleUploadShowBigImg($event)"
            @change="handleUploadImgByFileList($event, 'contentImgList')"
        ><div v-if="contentImgList.length < 1"><a-icon type="plus"/><div class="ant-upload-text">上传图片</div></div></a-upload>
      </a-form-item>
      <a-form-item label="链接">
        <a-input allowClear v-model="params.jumpTitle" class="w-500" placeholder='链接标题'></a-input>
        <a-input allowClear v-model="params.jumpLink" class="w-600" placeholder='链接路径'></a-input>
        <div class="font-weight-bold">请填写完整斗泉（非斗泉会导致小程序无法访问）路径如：<span class="color-red">https://www.douquan.com</span></div>
      </a-form-item>
      <a-form-item label="排序">
        <a-input allowClear v-model="params.sort" type="number" class="w-500" placeholder='排序'></a-input>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {returnAntUploadImgObj, updateBaseObjectWithComplex} from "@/untils/otherEvent"
import {
  postAddFaqContentApi,
  postEditFaqContentApi
} from "@/views/cmsPage/contentDataMange/faqAboutManage/faqListManage/_apis"
import _showUploadBigImgMixin from "@/_mixins/_showUploadBigImgMixin"
import _inputKeywordTabMixin from "@/_mixins/_inputKeywordTabMixin"
import {mapState} from "vuex"

export default {
  emits: ['success'],
  mixins: [_showUploadBigImgMixin, _inputKeywordTabMixin],
  data() {
    return {
      showPopup: false,
      layout: {
        labelCol: {span: 3}, //标签占5份  共24份
        wrapperCol: {span: 21},
      },
      contentImgList: [],
      params: {
        id: undefined,
        title: undefined, // 标题
        content: undefined, // 内容
        picUrl: undefined, // 图片
        jumpLink: undefined, // 链接
        jumpTitle: undefined, // 链接标题
        sort: undefined, // 排序
      },
      isEditStatus: false,
      titleText: '添加faq数据'
    }
  },
  computed: {
    ...mapState(['ip'])
  },
  methods: {
    show(data) {
      if (data) {
        this.isEditStatus = true
        this.titleText = '编辑faq数据'
        this.params = updateBaseObjectWithComplex(this.params, data)
        if (data.picUrl) {
          this.contentImgList = returnAntUploadImgObj([data.picUrl])
        }
      } else {
        this.isEditStatus = false
      }
      this.showPopup = true
      setTimeout(() => {
        this.addTabListeners(4)
      }, 500)
    },
    /** 确定修改 */
    async handleConfirm() {
      if (!this.params.title) return this.$message.warning('标题必须填写')
      if (!this.params.content && !this.contentImgList.length) return this.$message.warning('内容，图片两者必须输入一个')
      if ( (this.params.jumpLink && !this.params.jumpTitle) || (!this.params.jumpLink && this.params.jumpTitle)) return this.$message.warning('链接的标题，链接的路径必须全部填写')
      this.params.picUrl = this.contentImgList.map(el => el.url).join(',')
      this.$loading.show()
      let res
      if (this.isEditStatus) {
        res = await postEditFaqContentApi(this.params)
      } else {
        res = await postAddFaqContentApi(this.params)
      }
      this.$loading.hide()
      if (res.status !== '200') return;
      this.$message.info(`${this.isEditStatus ? '编辑成功' : '添加成功'}`)
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
        title: undefined, // 标题
        content: undefined, // 内容
        picUrl: undefined, // 图片
        jumpLink: undefined, // 链接
        jumpTitle: undefined, // 链接标题
        sort: undefined, // 排序
      }
      this.showPopup = false
    }
  }
}
</script>