<template>
  <a-modal v-model="showPopup" :width='500' title="内容" @close="handleCancel" :footer="null">
<!--  文本内容  -->
    <div class="content-text" v-if="popupData.content">{{ popupData.content }}</div>
<!--  图片  -->
    <img class="w-100-w mt-10" v-if="popupData.picUrl" :src="popupData.picUrl" alt="">
<!--  链接  -->
    <div
        class="mt-10 cur-pot color-dq-blue font-weight-bold"
        v-if="popupData.jumpLink"
        @click="handleGoToLinkUrl"
    >{{ popupData.jumpTitle }}</div>
  </a-modal>
</template>
<script>
import _showUploadBigImgMixin from "@/_mixins/_showUploadBigImgMixin"

export default {
  emits: ['success'],
  mixins: [_showUploadBigImgMixin],
  data() {
    return {
      showPopup: false,
      popupData: '',
    }
  },
  methods: {
    /** 去往链接页面 */
    handleGoToLinkUrl() {
      window.open(this.popupData.jumpLink, '_blank');
    },
    show(data) {
      this.popupData = data
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.popupData = ''
      this.showPopup = false
    }
  }
}
</script>
<style scoped lang="scss">
.content-text {
  white-space: pre-wrap
}
</style>