var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          ref: "printModalEl",
          attrs: {
            title: "打印编码预览",
            "ok-text": "确认",
            "cancel-text": "取消",
            keyboard: false
          },
          on: { cancel: _vm.handleCancelPrint, ok: _vm.handleConfirmPrint },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            { staticClass: "preview-content" },
            [
              _c(
                "VueEasyPrint",
                {
                  ref: "vueEasyPrintEl",
                  attrs: { "table-show": true, onePageRow: _vm.onePageRow }
                },
                _vm._l(_vm.codeData, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "print-content" },
                    [
                      item.barTitle
                        ? _c("div", { staticClass: "print-name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.barTitle.length > 15
                                    ? item.barTitle.substring(0, 13) + "..."
                                    : item.barTitle
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "print-line" }),
                      _c("div", { staticClass: "short-code" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              item.shortCode +
                                "-" +
                                item.id +
                                "" +
                                (item.reviewTip
                                  ? "-[" + item.reviewTip + "]"
                                  : "")
                            )
                          )
                        ])
                      ]),
                      item.versionName
                        ? _c("div", { staticClass: "font-size-16 mt-10" }, [
                            _vm._v(_vm._s(item.versionName))
                          ])
                        : _vm._e(),
                      _c("VueBAarCode", {
                        staticClass: "code-box",
                        attrs: {
                          value: item.barCode,
                          height: "20",
                          flat: true,
                          "margin-top": 0,
                          "text-margin": 0,
                          "font-size": "16",
                          format: "EAN13"
                        }
                      }),
                      item.orderAndServicePrice
                        ? _c("div", { staticClass: "print-name" }, [
                            _vm._v(
                              "价格：￥" + _vm._s(item.orderAndServicePrice)
                            )
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "page-break" })
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }