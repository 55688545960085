import {getFaqCategoryListByBigTypeApi} from "@/views/cmsPage/contentDataMange/faqAboutManage/_apis"

export default {
    data() {
        return {}
    },
    methods: {
        /** 根据应用场景获取faq小分类分类 */
        async handleSearchFaqCategory(str) {
            const res = await getFaqCategoryListByBigTypeApi(str)
            if (res.status !== '200') return
            this.smallFaqCategoryList = res.data
        }
    }
}