export default {
    data() {
        return {
            fileList: [],
            coverImgList: []
        }
    },
    methods: {
        /** 上传图片展示大图 */
        handleUploadShowBigImg(file) {
            this.$previewImg({
                list: [{img_url: `${file.url}`}],
                baseImgField: "img_url",
                showMute: false,
            });
        },
        /** 上传图片 */
        handleUploadImgByFileList({fileList}, uploadList) {
            fileList.map((row) => {
                try {
                    if (row.response.data) {
                        row.url = row.response.data;
                    }
                } catch (e) {}
            });
            if (uploadList) {
                this[uploadList] = fileList
            } else {
                this.fileList = fileList
            }
        },
    }
}