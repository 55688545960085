export default {
    data() {
        return {
            inputBoxEl: null,
            tabCount: 8,
            tempNullLength: '', // 默认空格
        }
    },
    methods: {
        // 添加监听器到多个输入框
        addTabListeners(num) {
            this.tabCount = num || 8
            this.computedNullLength()
            this.inputBoxEl = document.querySelectorAll('#inputKeywordTabDesc'); // 选择所有带特定类名的输入框
            this.inputBoxEl.forEach(inputBox => {
                inputBox.addEventListener('keydown', this.handleTabKey);
            });
        },
        /** 重新计算有几个空格 */
        computedNullLength() {
            this.tempNullLength = ''
            for (let k = 0; k < this.tabCount; k++) {
                this.tempNullLength += ' ';
            }
        },
        /** 监听事件 */
        handleTabKey(event) {
            if (event.key === 'Tab') {
                event.preventDefault(); // 阻止默认行为
                // 获取当前输入框和光标位置
                const inputBox = event.target;
                const cursorPos = inputBox.selectionStart;

                // 插入4个空格
                const textBefore = inputBox.value.substring(0, cursorPos);
                const textAfter = inputBox.value.substring(cursorPos);
                inputBox.value = textBefore + this.tempNullLength + textAfter;

                // 移动光标到插入空格后的位置
                inputBox.selectionStart = inputBox.selectionEnd = cursorPos + this.tabCount;
            }
        },
    },
    beforeDestroy() {
        if (this.inputBoxEl && this.inputBoxEl.length) {
            this.inputBoxEl.forEach(inputBox => {
                inputBox.removeEventListener('keydown', () => {});
            });
        }
    }
}