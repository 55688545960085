var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 850, title: _vm.titleText },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b({}, "a-form-model", _vm.layout, false),
        [
          _c(
            "a-form-item",
            { attrs: { label: "标题" } },
            [
              _c("a-input", {
                staticClass: "w-600",
                attrs: { allowClear: "", placeholder: "标题" },
                model: {
                  value: _vm.params.title,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "title", $$v)
                  },
                  expression: "params.title"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "内容" } },
            [
              _c("a-input", {
                staticClass: "w-100-w",
                attrs: {
                  id: "inputKeywordTabDesc",
                  type: "textarea",
                  "auto-size": { minRows: 6, maxRows: 20 },
                  placeholder: "文本内容"
                },
                model: {
                  value: _vm.params.content,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "content", $$v)
                  },
                  expression: "params.content"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "上传图片" } },
            [
              _c(
                "a-upload",
                {
                  key: "upload-cover",
                  attrs: {
                    action: _vm.ip + "/file/upload",
                    "list-type": "picture-card",
                    "file-list": _vm.contentImgList
                  },
                  on: {
                    preview: function($event) {
                      return _vm.handleUploadShowBigImg($event)
                    },
                    change: function($event) {
                      return _vm.handleUploadImgByFileList(
                        $event,
                        "contentImgList"
                      )
                    }
                  }
                },
                [
                  _vm.contentImgList.length < 1
                    ? _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传图片")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "链接" } },
            [
              _c("a-input", {
                staticClass: "w-500",
                attrs: { allowClear: "", placeholder: "链接标题" },
                model: {
                  value: _vm.params.jumpTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "jumpTitle", $$v)
                  },
                  expression: "params.jumpTitle"
                }
              }),
              _c("a-input", {
                staticClass: "w-600",
                attrs: { allowClear: "", placeholder: "链接路径" },
                model: {
                  value: _vm.params.jumpLink,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "jumpLink", $$v)
                  },
                  expression: "params.jumpLink"
                }
              }),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v("请填写完整斗泉（非斗泉会导致小程序无法访问）路径如："),
                _c("span", { staticClass: "color-red" }, [
                  _vm._v("https://www.douquan.com")
                ])
              ])
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "排序" } },
            [
              _c("a-input", {
                staticClass: "w-500",
                attrs: { allowClear: "", type: "number", placeholder: "排序" },
                model: {
                  value: _vm.params.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "sort", $$v)
                  },
                  expression: "params.sort"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }