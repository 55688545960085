var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: "内容", footer: null },
      on: { close: _vm.handleCancel },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _vm.popupData.content
        ? _c("div", { staticClass: "content-text" }, [
            _vm._v(_vm._s(_vm.popupData.content))
          ])
        : _vm._e(),
      _vm.popupData.picUrl
        ? _c("img", {
            staticClass: "w-100-w mt-10",
            attrs: { src: _vm.popupData.picUrl, alt: "" }
          })
        : _vm._e(),
      _vm.popupData.jumpLink
        ? _c(
            "div",
            {
              staticClass: "mt-10 cur-pot color-dq-blue font-weight-bold",
              on: { click: _vm.handleGoToLinkUrl }
            },
            [_vm._v(_vm._s(_vm.popupData.jumpTitle))]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }