<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  标题    -->
      <div slot="itemFaqTitleSlot" slot-scope="row">
        <div class="font-weight-bold">{{ row.title }}</div>
      </div>
<!--  内容    -->
      <div slot="itemFaqCenterSlot" slot-scope="row">
        <a-button @click="handleShowContent(row)">查看内容</a-button>
      </div>
<!--  类型    -->
      <div slot="itemFaqTypeSlot" slot-scope="row">
        <a-tag
            color="blue"
            class="tag-class"
            v-for="item in row.relVoList"
            :key="item.faqCategoryId"
        >{{ item.faqCategoryName + '-' + item.faqCategoryId }}</a-tag>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <div class="flex-center-center">
          <a-button type="primary" @click="handleEditor(row)">编辑</a-button>
          <div class="ml-5">
            <a-popconfirm title="确认删除当前faqn内容？" @confirm="handleDelete(row)">
              <a-button type="danger">删除</a-button>
            </a-popconfirm>
          </div>
        </div>
        <a-button class="mt-20" @click="handleAuthorize(row)">关联分类</a-button>
      </div>
    </a-table>
<!--  编辑弹窗  -->
    <EditorFaqListContentPopup ref="editorFaqListContentPopupEl" @success="handleSuccess"/>
<!--  授权列表  -->
    <EditorAuthorizePopup ref="editorAuthorizePopupEl" @success="handleSuccess"/>
<!--  查看内容  -->
    <ShowContentPopup ref="showContentPopupEl" @success="handleSuccess" />
  </div>
</template>
<script>
import {faqListTableColumns} from "@/views/cmsPage/contentDataMange/faqAboutManage/faqListManage/_data"
import {postDelFaqContentApi} from "@/views/cmsPage/contentDataMange/faqAboutManage/faqListManage/_apis"
import EditorFaqListContentPopup
  from "@/views/cmsPage/contentDataMange/faqAboutManage/faqListManage/_components/EditorFaqListContentPopup/index.vue"
import ShowContentPopup
  from "@/views/cmsPage/contentDataMange/faqAboutManage/faqListManage/_components/ShowContentPopup/index.vue"
import {faqBigCategoryClassType, faqBigCategoryListMapText} from "@/views/cmsPage/contentDataMange/faqAboutManage/_data"
import EditorAuthorizePopup
  from "@/views/cmsPage/contentDataMange/faqAboutManage/faqListManage/_components/EditorAuthorizePopup/index.vue"

export default {
  components: {EditorFaqListContentPopup, ShowContentPopup, EditorAuthorizePopup},
  props: ['pagination', 'list'],
  emits: ['success'],
  data() {
    return {
      faqBigCategoryClassType, // 应用场景
      tableList: faqListTableColumns,
    }
  },
  computed: {
    computedFaqBigCategoryListMapText() {
      return (value) => faqBigCategoryListMapText(value)
    }
  },
  methods: {
    /** 授权 */
    async handleAuthorize(row) {
      await this.$refs.editorAuthorizePopupEl.show(row)
    },
    /** 确定删除 */
    async handleDelete(row) {
      this.$loading.show()
      const res = await postDelFaqContentApi({id: row.id})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleSuccess()
    },
    /** 编辑 */
    handleEditor(row) {
      this.$refs.editorFaqListContentPopupEl.show(row)
    },
    /** 查看内容 */
    handleShowContent(row) {
      this.$refs.showContentPopupEl.show(row)
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>