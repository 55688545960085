import instance from "@/axios"

/** 获取faq小分类列表根据使用场景 */
export const getFaqCategoryListByBigTypeApi = async (str, type) => {
    return instance({
        url: '/dq_admin/faqQuestionCategory/list',
        method: 'GET',
        params: {
            name: str,
            type: type
        }
    })
}