<template>
  <a-modal v-model="showPopup" :width='800' title="授权" @close="handleCancel" @ok="handleConfirm">
    <a-checkbox-group class="w-100-w" v-model="categoryIdList" @change="handleChangeCheckGroup">
      <a-row class="w-100-w">
        <a-col v-for="item in smallFaqCategoryList" :key="item.id" :span="6">
          <a-checkbox class="mb-20" checked="" :value="item.id">{{ item.name }}</a-checkbox>
        </a-col>
      </a-row>
    </a-checkbox-group>
  </a-modal>
</template>
<script>
import _faqManageDataMixin from "@/views/cmsPage/contentDataMange/faqAboutManage/_mixins/_faqManageDataMixin"
import {postFaqContentAuthorizeApi} from "@/views/cmsPage/contentDataMange/faqAboutManage/faqListManage/_apis"

export default {
  emits: ['success'],
  mixins: [_faqManageDataMixin],
  data() {
    return {
      showPopup: false,
      popupData: '',
      categoryIdList: [],
      smallFaqCategoryList: [],
    }
  },
  methods: {
    async show(data) {
      this.popupData = data
      if (data.relVoList && data.relVoList.length) {
        this.categoryIdList = data.relVoList.map(el => el.faqCategoryId)
      } else {
        this.categoryIdList = []
      }
      await this.handleSearchFaqCategory()
      this.showPopup = true
    },
    /** 改变选择数据 */
    handleChangeCheckGroup(e) {
      console.log(e, '选择')
    },
    /** 确定 */
    async handleConfirm() {
      this.$loading.show()
      const res = await postFaqContentAuthorizeApi(this.popupData.id, this.categoryIdList)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('成功')
      this.$emit('success')
      this.showPopup = false
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.popupData = ''
      this.categoryIdList = []
      this.showPopup = false
    }
  }
}
</script>