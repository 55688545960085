import {objArrMapObj} from "@/untils/otherEvent"

/** faq大场景 order：订单 product：商品 shop：店铺 service_center：客服中心 */
export const faqBigCategoryList = [
    { name: '订单', value: 'order' },
    { name: '商品', value: 'product' },
    { name: '联系客服', value: 'service_center' },
    { name: '店铺数据', value: 'shop' },
]
export const faqBigCategoryListMapText = (value) => {
    return objArrMapObj('value', 'name', faqBigCategoryList)[value]
}

/** faq大场景对应的样式类 */
export const faqBigCategoryClassType = {
    order: 'color-blue',
    product: 'color-green',
    service_center: 'color-yellow',
    shop: 'color-dq-blue'
}

