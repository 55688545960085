<template>
  <div>
    <a-modal
        :width="1000"
        v-model="show"
        title="质检鉴定"
        ok-text="确认"
        :keyboard="false"
        cancel-text="取消"
        @cancel="resetForms"
        @ok="hideModal"
    >
      <a-form-model
          ref="ruleForm"
          :model="params"
          :rules="rules"
          v-bind="layout"
      >
        <a-form-item label="送评分类">
          <a-tooltip>
            <template slot="title">
              {{ computedRateServicePayTypeTextByDesc(params.orderRatingServiceRank) }}
            </template>
            <span class="fs-20" :class="dqCheckPayClassType[params.orderRatingServiceRank]">{{ computedRateServicePayTypeText(params.orderRatingServiceRank) }}</span>
          </a-tooltip>
        </a-form-item>
        <a-form-item label="卖家描述瑕疵" v-if="params.productRemark">
          <div class="color-red ml-5">{{ params.productRemark }}</div>
        </a-form-item>
        <a-form-item label="D类质检数量">
          <div class="color-red ml-5">{{ params.coinAmount }}</div>
        </a-form-item>
        <a-form-item label="是否进行标版">
          <div>
            <span :class="dqCheckVersionStatusClassType[params.duibanService]">{{ computedVersionStatusTypeText(params.duibanService) }}</span>
          </div>
        </a-form-item>
        <a-form-item label="质检状态">
          <a-select
              :dropdownMatchSelectWidth="false"
              :filter-option="untils.filterOption"
              :dropdownMenuStyle="{'max-height': '350px'}"
              allowClear
              showSearch
              style="margin: 5px; width: 180px;"
              v-model="params.reviewStatus"
              placeholder="请选择质检状态"
          >
            <a-select-option
                v-for="items of computedCheckStatusTypeList(params.orderRatingServiceRank)"
                :key="items.value"
                :value="items.value"
            >{{ items.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="质检结果" v-if="params.reviewStatus === 10">
          <a-select
              :dropdownMatchSelectWidth="false"
              :filter-option="untils.filterOption"
              :dropdownMenuStyle="{'max-height': '350px'}"
              allowClear
              showSearch
              style="margin: 5px; width: 180px;"
              v-model="params.responsibilityDetermine"
              placeholder="请选择质检结果"
          >
            <a-select-option
                v-for="items of computedCheckResultTypeList(params.orderRatingServiceRank)"
                :key="items.value"
                :value="items.value"
            >{{ items.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="质检图片">
          <div v-for="(item, index) in DTypeUploadList" :key="index">
            <div class="rate-service-img-name">
              <span>质检拍品名称：</span>
              <a-input
                allowClear
                v-model="item.title"
                class="w-200"
                placeholder='标题'
              ></a-input>
              <a-input
                  v-if="params.duibanService"
                  allowClear
                  v-model="item.versionName"
                  class="w-250 ml-5"
                  placeholder='质检拍品版别-可选'
              ></a-input>
              <a-button
                  v-if="!item.editorFlag"
                  @click="handleDeleteUploadData(index)"
                  class="ml-5"
                  type="danger"
              > <a-icon type="delete" />删除</a-button>
            </div>
            <a-upload
                :key="index"
                :action="ip + '/file/upload'"
                :data="{rotate: 180}"
                list-type="picture-card"
                :file-list="item.images"
                :multiple="true"
                @preview="handlePreview($event, index)"
                @change="handleChangeCoverList($event, index)"
            >
              <div v-if="item.images.length < 15">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
          </div>
          <a-button @click="handleAddUploadData" type="primary"> <a-icon type="plus" />新增</a-button>
        </a-form-item>
        <a-form-model-item label="质检描述">
          <a-textarea
              v-model="params.reviewRemarks"
              placeholder="质检描述"
              :rows="4" />
          <div class="color-red">*填写之后买卖双方都将展示，请谨慎填写</div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import untils from "@/untils";
import {
  computedVersionStatusType,
  dqCheckPayClassType,
  dqCheckQualityType,
  dqCheckResultTypeList,
  dqCheckStatusTypeList, dqCheckVersionStatusClassType,
  findCheckResultTypeList,
  findCheckStatusTypeList,
  rateServicePayTypeMapText,
  rateServicePayTypeMapTextByDesc
} from "@/views/cmsPage/checkManage/_data"
export default {
  props: ["detail"],
  data() {
    return {
      untils: untils,
      show: false,
      dqCheckResultTypeList,
      dqCheckStatusTypeList,
      dqCheckQualityType,
      dqCheckPayClassType,
      dqCheckVersionStatusClassType,
      params: {
        orderId: undefined,
        // 评级结果
        reviewStatus: undefined,
        // 质检异常结果
        responsibilityDetermine: undefined,
        // 描述
        reviewRemarks: undefined,
        // 图片
        receiptImage: undefined,
      },
      coverList: [],
      imgKey: "",
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      rules: {},
      isLoading: false,
      DTypeUploadList: [
        {title: undefined, images: [], shortCode: undefined, editorFlag: false, versionName: undefined}
      ]
    };
  },
  computed: {
    ...mapState(["ip"]),
    computedVersionStatusTypeText() {
      return (value) => computedVersionStatusType(value)
    },
    computedRateServicePayTypeTextByDesc() {
      return (value) => rateServicePayTypeMapTextByDesc(value)
    },
    computedRateServicePayTypeText() {
      return (value) => rateServicePayTypeMapText(value)
    },
    computedCheckResultTypeList() {
      return (value) => findCheckResultTypeList(value)
    },
    computedCheckStatusTypeList() {
      return (value) => findCheckStatusTypeList(value)
    }
  },
  methods: {
    /** 删除上传数据 */
    handleDeleteUploadData(index) {
      this.DTypeUploadList.splice(index, 1)
    },
    /** 增加上传数据 */
    handleAddUploadData() {
      this.DTypeUploadList.push({title: '', images: [], shortCode: '', editorFlag: false, versionName: undefined})
    },
    async showPopup(data) {
      this.resetForms()
      await this.setDetail(data)
      this.show = true
    },
    setDetail(data) {
      if (!data.reviewStatus) {
        data.reviewStatus = undefined
      }
      if (!data.responsibilityDetermine) {
        data.responsibilityDetermine = undefined
      }
      this.params = Object.assign(this.params, data)
      if (this.params.orderRatingServiceRank === 'D') {
        this.params.reviewStatus = 5
      }
      if (this.params.items) {
        this.editorFlag = true
        let tempList = []
        this.params.items.forEach(el => {
          let tempImages = []
          if (el.images && el.images.length) {
            tempImages = el.images.map((ele, i) => {
              return {uid: i, name: "image", status: "done", url: ele,}
            })
          }
          tempList.push({
            title: el.title,
            images: tempImages,
            shortCode: el.shortCode,
            editorFlag: true,
            versionName: el.versionName
          })
        })
        this.DTypeUploadList = tempList
      }
      if (this.DTypeUploadList.length < 5) {
        const numberCount = data.coinAmount - this.DTypeUploadList.length
        for(let i = 1; i <= numberCount; i++) {
          this.handleAddUploadData()
        }
      }
    },
    handleChangeCoverList({ fileList }, index) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
            row.thumbUrl = row.url
          }
        } catch (e) {}
      });
      const tempData = JSON.parse(JSON.stringify({
        title: this.DTypeUploadList[index].title,
        images: fileList,
        versionName: this.DTypeUploadList[index].versionName
      }))
      this.$set(this.DTypeUploadList, index, tempData);
    },
    handlePreview(file, index) {
      const fileList = this.DTypeUploadList[index].images.map(el => {
        return {img_url: el.url}
      })
      this.$previewImg({
        list: fileList,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    /** 判断那一组图片没有上传 */
    handleComputedImageListIsNull() {
      const foundIndex = this.DTypeUploadList.findIndex(el => el.images.length < 1 || !el.title)
      if (foundIndex < 0) {
        return false
      } else {
        return foundIndex + 1
      }
    },
    /** 将图片计算成可提交的数据 */
    handleComputedUploadImgList() {
      let tempList = []
      this.DTypeUploadList.forEach(el => {
        tempList.push({
          title: el.title,
          images: el.images.map(row => row.url),
          shortCode: el.shortCode,
          versionName: el.versionName
        })
      })
      return tempList
    },
    hideModal() {
      if (this.isLoading) return this.$message.warn('请勿重复提交')
      if (!this.params.reviewStatus) return this.$message.warn('评级结果不能为空')
      if (this.handleComputedImageListIsNull()) return this.$message.warn(`第${this.handleComputedImageListIsNull()}组图片或标题不能为空`)
      if (this.params.reviewStatus === 5) this.params.responsibilityDetermine = undefined
      this.submit()
    },
    async submit() {
      const tempParams = {
        ratingId: this.params.id,
        reviewStatus: this.params.reviewStatus,
        reviewRemarks: this.params.reviewRemarks,
        items: this.handleComputedUploadImgList()
      }
      const url = '/dq_admin/qualityzhandian/dQualityReviewAndGenerateBar'
      this.isLoading = true
      const res = await this.axios.post(
          url,
          tempParams
      );
      this.isLoading = false
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.resetForms()
      this.$emit("success", this.params.expressNo);
    },
    resetForms() {
      this.params = {
        orderId: undefined,
        // 评级结果
        reviewStatus: undefined,
        responsibilityDetermine: undefined,
        // 描述
        reviewRemarks: undefined,
        // 图片
        receiptImage: undefined
      }
      this.DTypeUploadList = [{title: undefined, images: [], barCode: undefined, editorFlag: false, versionName: undefined}]
      this.show = false
      this.$emit('closeModal')
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img {
    height: 100%;
  }
  .preview-image-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
.preview-image {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img {
    height: 100%;
  }
  .preview-image-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
.fs-20 {
  font-size: 20px;
}
</style>
