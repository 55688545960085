var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 800, title: "授权" },
      on: { close: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-checkbox-group",
        {
          staticClass: "w-100-w",
          on: { change: _vm.handleChangeCheckGroup },
          model: {
            value: _vm.categoryIdList,
            callback: function($$v) {
              _vm.categoryIdList = $$v
            },
            expression: "categoryIdList"
          }
        },
        [
          _c(
            "a-row",
            { staticClass: "w-100-w" },
            _vm._l(_vm.smallFaqCategoryList, function(item) {
              return _c(
                "a-col",
                { key: item.id, attrs: { span: 6 } },
                [
                  _c(
                    "a-checkbox",
                    {
                      staticClass: "mb-20",
                      attrs: { checked: "", value: item.id }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }