import instance from "@/axios"

/** 获取faq列表数据API */
export const getFaqListDataApi = async (params) => {
    return instance({
        url: '/dq_admin/faq/list',
        method: 'GET',
        params: params
    })
}
/** 新增内容-faq内容 */
export const postAddFaqContentApi = async (params) => {
    return instance({
        url: '/dq_admin/faq/add',
        method: 'post',
        data: params
    })
}
/** 编辑内容-faq内容 */
export const postEditFaqContentApi = async (params) => {
    return instance({
        url: '/dq_admin/faq/edit',
        method: 'post',
        data: params
    })
}
/** 删除内容-faq内容 */
export const postDelFaqContentApi = async (params) => {
    return instance({
        url: '/dq_admin/faq/delById',
        method: 'get',
        params: params
    })
}


/** 编辑内容授权列表 */
export const postFaqContentAuthorizeApi = async (faqQuestionId, faqCategoryIds) => {
    return instance({
        url: '/dq_admin/faqCategoryQuestionRel/add',
        method: 'post',
        data: {
            faqQuestionId,
            faqCategoryIds
        }
    })
}