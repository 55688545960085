var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemFaqTitleSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(row.title))
                ])
              ])
            }
          },
          {
            key: "itemFaqCenterSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.handleShowContent(row)
                        }
                      }
                    },
                    [_vm._v("查看内容")]
                  )
                ],
                1
              )
            }
          },
          {
            key: "itemFaqTypeSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.relVoList, function(item) {
                  return _c(
                    "a-tag",
                    {
                      key: item.faqCategoryId,
                      staticClass: "tag-class",
                      attrs: { color: "blue" }
                    },
                    [
                      _vm._v(
                        _vm._s(item.faqCategoryName + "-" + item.faqCategoryId)
                      )
                    ]
                  )
                }),
                1
              )
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "div",
                    { staticClass: "flex-center-center" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleEditor(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-5" },
                        [
                          _c(
                            "a-popconfirm",
                            {
                              attrs: { title: "确认删除当前faqn内容？" },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleDelete(row)
                                }
                              }
                            },
                            [
                              _c("a-button", { attrs: { type: "danger" } }, [
                                _vm._v("删除")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "mt-20",
                      on: {
                        click: function($event) {
                          return _vm.handleAuthorize(row)
                        }
                      }
                    },
                    [_vm._v("关联分类")]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("EditorFaqListContentPopup", {
        ref: "editorFaqListContentPopupEl",
        on: { success: _vm.handleSuccess }
      }),
      _c("EditorAuthorizePopup", {
        ref: "editorAuthorizePopupEl",
        on: { success: _vm.handleSuccess }
      }),
      _c("ShowContentPopup", {
        ref: "showContentPopupEl",
        on: { success: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }